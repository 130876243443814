@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
.Expense-div {
  display: flex;
  height: 8rem;
  background-color: #151515;
  align-items: center;
  justify-content: space-between;
  margin: 0px 100px;
  padding: 20px;
  /* border-radius: 12px;
  border-top-right-radius: 0;
  border-top-left-radius: 0; */
  box-shadow: 0 0 15px rgba(26, 26, 26, 0.488);
  transition: transform 0.5s ease-in-out;
  font-family: "Dosis", sans-serif;
  -webkit-transition: transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out;
  -ms-transition: transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
}

.Expense-div__Date {
  color: rgb(140, 0, 248);
  display: flex;
  flex-direction: column;
  font-size: larger;
}

.Expense-div__Expense-Reason {
  font-size: larger;
  color: rgb(255, 255, 255);
}

.Expense-div__Amount {
  display: flex;
  align-items: center;
  background-color: #6c00ab;
  padding: 15px;
  border-radius: 7px;
  font-size: larger;
  color: rgba(240, 248, 255, 0.562);
  transition: color 0.2s ease-out;
  position: relative;
}

.Expense-div__Amount span:first-of-type {
  font-size: 25px;
  position: absolute;
  transform: translateX(-30px);
}

.Expense-div__Amount span:first-of-type .plis {
  color: rgb(0, 255, 0);
}

.Expense-div__Amount span:first-of-type .minus {
  color: rgb(255, 0, 0);
}

.Expense-div:hover .Expense-div__Amount {
  color: rgba(240, 248, 255, 0.995);
}
