@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Combo&family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");

input {
  all: unset;
}
.form-tag {
  position: relative;
}
.form-div {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 200px;
  position: relative;
}
.form-div form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 80%;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 520px;
  height: 100%;
}

.form div {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-start;
  width: 520px;
  /* height: 100%; */
}

.form-input__date,
.form-input__reason,
.form-input__amount {
  padding: 10px 10px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  background-color: aliceblue;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.636);
  font-family: "Combo", cursive;
  margin: 10px 30px;
  height: 20px;
}
.form-input__date {
  width: 120px;
  padding-top: 15px;
}

.form-input__reason {
  width: 440px;
}
.form-input__amount {
  width: 250px;
}
button {
  all: unset;
}
.form-show-button,
.form__button {
  padding: 10px 30px;
  font-family: "Dosis", sans-serif;
  /* background-color: rgb(184, 165, 251); */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.third {
  border-color: #6e34db;
  color: #fff;
  box-shadow: 0 0 40px 40px #5327a4 inset, 0 0 0 0 #5327a4;
  color: black;
  transition: box-shadow 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11),
    color 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11);
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11),
    color 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11);
  -moz-transition: box-shadow 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11),
    color 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11);
  -ms-transition: box-shadow 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11),
    color 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11);
  -o-transition: box-shadow 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11),
    color 0.4s cubic-bezier(0.7, 0.48, 0.01, 1.11);
}
.third:hover {
  box-shadow: 0 0 10px 0 #6e34db inset, 0 0 10px 4px #6e34db;
  color: white;
}

.form__buttons {
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
