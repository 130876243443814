@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 0px;
}


html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #3f3f3f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
}
