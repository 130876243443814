.expense-date {
  display: flex;
  flex-direction: column;
  width: 5.5rem;
  height: 5.5rem;
  border: 1px solid #ececec;
  background: #686868 url("./resources/cal.svg") center/100%;
  color: rgb(215, 215, 215);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}

.Expense-div:hover .expense-date {
  background: rgba(156, 156, 156, 0.831372549) url("./resources/cal.svg")
    center/100%;
  color: white;
}

.expense-date__month {
  font-size: 1rem;
  font-weight: bold;
}

.expense-date__year {
  font-size: 0.5rem;
}

.expense-date__day {
  font-size: 1.7rem;
  font-weight: bold;
}
